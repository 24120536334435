<template>
  <div class="home">
    <CHeader with-subheader>
      <CHeaderBrand class="mx-auto" to="/">
        <img
          id="banner-logo"
          class="d-none d-sm-block"
          alt="Vue logo"
          src="../assets/Aarcomm_io-Logo.png"
        />
      </CHeaderBrand>
    <div v-if="!$auth.loading">
      <CHeaderNav v-if="$auth.isAuthenticated">
        <CHeaderNavItem class="px-3 pull-right">
          <button
            @click="homeClick"
            class="c-header-nav-btn"
            v-c-tooltip="'Home'"
          >
            <CIcon name="cil-home" />
          </button>
        </CHeaderNavItem>
        <TheHeaderDropdownAccntSimple v-if="$auth.isAuthenticated" />
      </CHeaderNav>
      <CHeaderNav v-else>
                <CHeaderNavItem class="px-3 pull-right">
          <button
            @click="login"
            class="c-header-nav-btn"
            
          >
            <CIcon name="cil-user" />Log-in
          </button>
        </CHeaderNavItem>
      </CHeaderNav>
      </div>
    </CHeader>
    <Welcome msg="Welcome to Aarcomm IoT" />
    <CRow class="content">
      <CCol class="col-10">
        <h1>{{ msg }}</h1>
        <p class="content-message">
          Advancing Remote Control Technologies.
          <br />find your solution at
          <CLink href="https://www.aarcomm.com/" target="_blank" rel="noopener"
            >aarcomm.com</CLink
          >.
        </p>
        <div v-if="!$auth.loading">
        <h3 v-if="!$auth.isAuthenticated">Log-in to IoT</h3>
        </div>
        <br />
        <!--button id="myBtn" onclick="myFunction()">Pause</button-->

        <!-- Check that the SDK client is not currently loading before accessing is methods -->
        <div v-if="!$auth.loading">
          <!-- show login when not authenticated -->
          <CButton id="myBtn" v-if="!$auth.isAuthenticated" @click="login"
            >Log in</CButton
          >
          
           <CButton id="myBtn" color="secondary" v-if="$auth.isAuthenticated" @click="homeClick"
            >Enter</CButton
          >
          
          <!-- show logout when authenticated -->
          <CButton v-if="$auth.isAuthenticated" @click="logout"
            >Or Click here to Log Out</CButton
          >
        </div>
      </CCol>
    </CRow>
  </div>
</template>




<script>
// @ is an alias to /src
import Welcome from "@/components/Welcome.vue";
import TheHeaderDropdownAccntSimple from "@/layouts/containers/TheHeaderDropdownAccntSimple.vue";

export default {
  name: "home",
  data() {
    return {
      msg: "Welcome to Aarcomm IoT",
    };
  },
  components: {
    Welcome,
    TheHeaderDropdownAccntSimple,
  },

  methods: {
    async homeClick(e) {
      try {
        console.log(e);
        this.$router.push("/home");
      } catch (err) {
        console.log(err);
      }
    },
    // Log the user in
    async login() {
      await this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin, // TODO: THis should not be hardcoded!!!
      });
    },
  },
};
</script>


<style scoped>
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.content-message{
  color: #f1f1f1;
}

#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #007cba;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

#banner-logo {
  max-height: 100px;
  max-width: 90%;
  margin: 1px auto;
  padding: auto;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
