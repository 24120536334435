var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "CHeader",
        { attrs: { "with-subheader": "" } },
        [
          _c("CHeaderBrand", { staticClass: "mx-auto", attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "d-none d-sm-block",
              attrs: {
                id: "banner-logo",
                alt: "Vue logo",
                src: require("../assets/Aarcomm_io-Logo.png")
              }
            })
          ]),
          !_vm.$auth.loading
            ? _c(
                "div",
                [
                  _vm.$auth.isAuthenticated
                    ? _c(
                        "CHeaderNav",
                        [
                          _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3 pull-right" },
                            [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: "Home",
                                      expression: "'Home'"
                                    }
                                  ],
                                  staticClass: "c-header-nav-btn",
                                  on: { click: _vm.homeClick }
                                },
                                [_c("CIcon", { attrs: { name: "cil-home" } })],
                                1
                              )
                            ]
                          ),
                          _vm.$auth.isAuthenticated
                            ? _c("TheHeaderDropdownAccntSimple")
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "CHeaderNav",
                        [
                          _c(
                            "CHeaderNavItem",
                            { staticClass: "px-3 pull-right" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "c-header-nav-btn",
                                  on: { click: _vm.login }
                                },
                                [
                                  _c("CIcon", { attrs: { name: "cil-user" } }),
                                  _vm._v("Log-in ")
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("Welcome", { attrs: { msg: "Welcome to Aarcomm IoT" } }),
      _c(
        "CRow",
        { staticClass: "content" },
        [
          _c("CCol", { staticClass: "col-10" }, [
            _c("h1", [_vm._v(_vm._s(_vm.msg))]),
            _c(
              "p",
              { staticClass: "content-message" },
              [
                _vm._v(" Advancing Remote Control Technologies. "),
                _c("br"),
                _vm._v("find your solution at "),
                _c(
                  "CLink",
                  {
                    attrs: {
                      href: "https://www.aarcomm.com/",
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [_vm._v("aarcomm.com")]
                ),
                _vm._v(". ")
              ],
              1
            ),
            !_vm.$auth.loading
              ? _c("div", [
                  !_vm.$auth.isAuthenticated
                    ? _c("h3", [_vm._v("Log-in to IoT")])
                    : _vm._e()
                ])
              : _vm._e(),
            _c("br"),
            !_vm.$auth.loading
              ? _c(
                  "div",
                  [
                    !_vm.$auth.isAuthenticated
                      ? _c(
                          "CButton",
                          { attrs: { id: "myBtn" }, on: { click: _vm.login } },
                          [_vm._v("Log in")]
                        )
                      : _vm._e(),
                    _vm.$auth.isAuthenticated
                      ? _c(
                          "CButton",
                          {
                            attrs: { id: "myBtn", color: "secondary" },
                            on: { click: _vm.homeClick }
                          },
                          [_vm._v("Enter")]
                        )
                      : _vm._e(),
                    _vm.$auth.isAuthenticated
                      ? _c("CButton", { on: { click: _vm.logout } }, [
                          _vm._v("Or Click here to Log Out")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }