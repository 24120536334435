<template>
  <div class="hello">
    <div>
      <video autoplay muted loop id="myVideo" :src="mediaSrc">
        <source src="mediaSrc" type="video/mp4" />Your browser does not support HTML5 video.
      </video>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {
    msg: String, 
    media: {type:String, default:"/Media1.mp4" } // Specify a Video source to display
  },
  computed:{
    mediaSrc(){
      if(this.media){
        return this.media;
      }
      return "/Media1.mp4";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #007cba;
}

/** Video Embed Code */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial;
  font-size: 17px;
}

#myVideo {
  position: fixed;
  right: 0;
  left:0; 
  bottom: 1; /** Set this to anything other than 0 to avoid covering all content  */
  min-width: 100%;
  min-height: 100%;
}


</style>
