var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hello" }, [
    _c("div", [
      _c(
        "video",
        {
          attrs: {
            autoplay: "",
            muted: "",
            loop: "",
            id: "myVideo",
            src: _vm.mediaSrc
          },
          domProps: { muted: true }
        },
        [
          _c("source", { attrs: { src: "mediaSrc", type: "video/mp4" } }),
          _vm._v("Your browser does not support HTML5 video. ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }